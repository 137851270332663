import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { isAfter, endOfYesterday } from "date-fns";
import classNames from "classnames";
import { deleteMeet } from "util/pouchActions";
import { reformatDateToUsFormat, parseDateString } from "util/dateHelper";
import { fetchWrapper } from "util/api";
import { getMeets, getMeetMetaData } from "selectors";
import { importMeet } from "util/pouchAdapter";
import FileUpload from "components/fileUpload/FileUpload";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import LogoIcon from "icons/LogoIcon";
import FileIcon from "icons/FileIcon";
import NewMeetModal from "app/newMeetModal/NewMeetModal";
import HomeNavigation from "app/HomeNavigation";
import CheckIcon from "icons/CheckIcon";

class OnlineMeetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      collapsed: true,
    };
  }

  render() {
    const { title, meets, isLoadingMeets, isLoadingMeetsFailure } = this.props;
    const anchor = kebabCase(title);

    const filteredMeets = meets.filter(
      (m) =>
        m.name &&
        m.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
    );
    const collapsed =
      this.state.collapsed &&
      !isLoadingMeetsFailure &&
      (isLoadingMeets || filteredMeets.length > 20);

    return (
      <div>
        <div
          className={classNames(
            "meets-table-table-wrapper",
            collapsed && "meets-table-collapsed"
          )}
        >
          <table className="table">
            <thead>
              <tr>
                <td colSpan="3">
                  <div className="table-title">
                    <a href={`#${anchor}`} name={anchor}>
                      {title}
                    </a>
                    <input
                      placeholder="Search..."
                      type="text"
                      value={this.state.searchQuery}
                      onChange={(e) =>
                        this.setState({
                          searchQuery: e.target.value,
                          collapsed: false,
                        })
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ width: "70%" }}>Name</th>
                <th style={{ width: "30%" }}>Date</th>
              </tr>
            </thead>
            <tbody>
              {!isLoadingMeets &&
                !isLoadingMeetsFailure &&
                map(filteredMeets, (meet) => {
                  if (!meet.showOnHomePage) {
                    return null;
                  }

                  return (
                    <tr key={meet._id}>
                      <td>
                        <Link to={`/meets/${meet._id}/results`}>
                          {get(meet, "name")}
                        </Link>
                      </td>
                      <td>
                        {reformatDateToUsFormat({
                          dateString: meet.date,
                          meet,
                        })}
                      </td>
                    </tr>
                  );
                })}
              {isLoadingMeets && (
                <tr>
                  <td colSpan={2}>Loading...</td>
                </tr>
              )}
              {isLoadingMeetsFailure && (
                <tr>
                  <td colSpan={2}>Failed to load meet data!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {collapsed && (
          <div className="meet-table-show-more">
            <button onClick={() => this.setState({ collapsed: false })}>
              Show More
            </button>
          </div>
        )}
      </div>
    );
  }
}

class Meets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewMeetModalOpen: false,
      newMeet: this.getEmptyMeet(),
      onlineMeets: [],
      isLoadingMeets: false,
      isLoadingMeetsFailure: false,
      importingMeetFromDataFile: false,
      importingMeetFromDataFileId: "",
      scrolled: false,
    };
  }

  onScroll = () => {
    if (document.getElementById("waypoint")?.getBoundingClientRect().top < 45) {
      this.setState({ scrolled: true });
    } else {
      if (this.state.scrolled) {
        this.setState({ scrolled: false });
      }
    }
  };

  componentDidMount() {
    this.getAllMeets();
    document.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  getEmptyMeet = () => {
    return {
      name: "",
      date: "",
    };
  };

  getAllMeets = (meetId, password) => {
    this.setState({ isLoadingMeets: true });
    fetchWrapper("/api/meets", "GET")
      .then((response) => {
        this.setState({
          onlineMeets: get(response, "docs", []),
          isLoadingMeets: false,
        });
      })
      .catch(() => {
        this.setState({ isLoadingMeetsFailure: true, isLoadingMeets: false });
      });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ newMeet: { ...this.state.newMeet, [name]: value } });
  };

  handleDateChange = (value) => {
    this.setState({ newMeet: { ...this.state.newMeet, date: value } });
  };

  deleteMeet = (meet) => {
    const isOnline =
      this.props.meetMetaData[meet._id].isOnline ||
      find(this.state.onlineMeets, { _id: meet._id });
    const message = !isOnline
      ? `Are you sure you want to delete ${meet.name}? Data loss will be permanent`
      : `Are you sure you want to delete ${meet.name}? This will only delete the local copy. The meet will still be available online. Contact support to delete from online database.`;
    if (window.confirm(message)) {
      deleteMeet(meet);
    }
  };

  openNewMeetModal = () => {
    this.setState({ isNewMeetModalOpen: true });
  };

  closeNewMeetModal = () => {
    this.setState({ isNewMeetModalOpen: false });
  };

  importMeetFromDataFile = (docString) => {
    this.setState({ importingMeetFromDataFile: true }, () => {
      const importedMeetId = importMeet(docString);
      if (importedMeetId) {
        this.setState({ importingMeetFromDataFileId: importedMeetId });
      } else {
        // something went wrong
        this.setState({ importingMeetFromDataFile: false });
      }
    });
  };

  render() {
    const { meets, meetMetaData } = this.props;
    const { onlineMeets, isLoadingMeets, isLoadingMeetsFailure } = this.state;

    const localMeets = filter(meets, (meet) => {
      return get(meetMetaData, [meet._id, "isLocal"], false);
    });

    const visibleOnlineMeets = filter(onlineMeets, (m) => m.showOnHomePage);

    const upcomingMeets = filter(visibleOnlineMeets, (m) =>
      isAfter(
        parseDateString({ dateString: m.date, meet: m }),
        endOfYesterday()
      )
    );
    const sortedUpcomingMeets = orderBy(
      upcomingMeets,
      [
        (m) => parseDateString({ dateString: m.date, meet: m }),
        (m) => m.name?.toLowerCase(),
      ],
      ["asc", "asc"]
    );

    const pastMeets = filter(
      visibleOnlineMeets,
      (m) =>
        !isAfter(
          parseDateString({ dateString: m.date, meet: m }),
          endOfYesterday()
        )
    );
    const sortedPastMeets = orderBy(
      pastMeets,
      [
        (m) => parseDateString({ dateString: m.date, meet: m }),
        (m) => m.name?.toLowerCase(),
      ],
      ["desc", "asc"]
    );

    const sortedLocalMeets = orderBy(
      localMeets,
      [
        (m) => parseDateString({ dateString: m.date, meet: m }),
        (m) => m.name?.toLowerCase(),
      ],
      ["desc", "asc"]
    );

    return (
      <div className="meets">
        <HomeNavigation scrolled={this.state.scrolled} />
        <div className="logo-wrapper">
          <LogoIcon className="logo animate" />
        </div>
        <div id="waypoint" />

        <OnlineMeetsTable
          meets={sortedUpcomingMeets}
          title="Upcoming Meets"
          isLoadingMeets={isLoadingMeets}
          isLoadingMeetsFailure={isLoadingMeetsFailure}
        />
        <OnlineMeetsTable
          meets={sortedPastMeets}
          title="Recent Meets"
          isLoadingMeets={isLoadingMeets}
          isLoadingMeetsFailure={isLoadingMeetsFailure}
        />
        <div className="meets-table-table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <td colSpan="3">
                  <a href="#your-meets" name="your-meets">
                    Your Meets (Available Offline)
                  </a>
                </td>
              </tr>
              <tr>
                <th style={{ width: "65%" }}>Name</th>
                <th style={{ width: "25%", minWidth: 110 }}>Date</th>
                <th style={{ width: "8%", minWidth: 55 }}></th>
              </tr>
            </thead>
            <tbody>
              {map(sortedLocalMeets, (meet) => {
                return (
                  <tr key={meet._id}>
                    <td>
                      <Link to={`/meets/${meet._id}/setup`}>
                        {get(meet, "name")}
                      </Link>
                    </td>
                    <td>{get(meet, "date")}</td>
                    <td>
                      <button onClick={() => this.deleteMeet(meet)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <button className="new-meet-button" onClick={this.openNewMeetModal}>
          <AddIcon />
          &nbsp;Create New Meet
        </button>
        <NewMeetModal
          isOpen={this.state.isNewMeetModalOpen}
          onRequestClose={this.closeNewMeetModal}
        />

        {this.state.importingMeetFromDataFile &&
        !localMeets.find(
          (m) => m._id === this.state.importingMeetFromDataFileId
        ) ? (
          <button style={{ marginTop: 20 }} disabled>
            Loading data file...
          </button>
        ) : (
          <FileUpload
            accept="json"
            buttonText={
              <span>
                <FileIcon />
                &nbsp;Upload Meet From Data File
              </span>
            }
            onChange={this.importMeetFromDataFile}
          />
        )}

        <div className="about-us">
          LiftingCast is Powerlifting meet management software. LiftingCast can
          be used offline or online.
        </div>

        <div className="about-link">
          <Link to="/about">More Info - FAQ</Link>
        </div>

        <div className="about-link">
          <a href="/changes.html">Changelog</a>
        </div>

        <div className="about-link">
          <Link to="/instructions">Meet Director Instructions</Link>
        </div>

        <div className="about-link">
          <Link to="/coach">Instructions for coaches</Link>
        </div>

        <div className="about-link">
          <Link to="/contact">Contact</Link>
        </div>

        <div className="meets-table-table-wrapper">
          <table className="table features-table">
            <thead>
              <tr>
                <th colSpan="3">
                  <a href="#features" name="features">
                    Features
                  </a>
                </th>
              </tr>
              <tr>
                <th></th>
                <th>Free</th>
                <th>Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Auto Calculate Lifting Order</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Auto Calculate Results</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Attempt Timer</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Custom Awards Divsion Setup</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Multiple Divisions Per Lifter</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>4th Attempts</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Print Lifter Attempt Cards</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Download Backup of Your Meet Data</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Export Results</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Export Division Configuration</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Export Lifter Data</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Import Division and Lifters</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Custom Configure Display Page</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Custom Plate Colors</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Live Video Feed Overlays</td>
                <td>
                  <CheckIcon />
                </td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Broadcast Live Data Online</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Online Backup of Data</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Sync Data Across Multiple Computers</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Combine Results Across Multiple Plaforms</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Online Lifter Registration</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Run a Virtual Meet</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Ref Lights</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
              <tr>
                <td>Lifters Enter Their Own Rack Heights</td>
                <td></td>
                <td>
                  <CheckIcon />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="version-info">Version 2.1.0</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meets: getMeets(state),
    meetMetaData: getMeetMetaData(state),
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(Meets);
