import React from "react";
import { EligibleRecord, Meet } from "types";
import {
  getEligibleRecordsForLifter,
  getRecordWeightForEligibleRecord,
} from "util/records";
import map from "lodash/map";
import { useMeet } from "util/useMeet";
import { useLifter } from "util/useLifter";
import FormattedEligibleRecord from "components/FormattedEligibleRecord";

const RecordsForType = ({
  meet,
  eligibleRecord,
}: {
  meet: Meet;
  eligibleRecord: EligibleRecord;
}) => {
  if (!meet.records) {
    return null;
  }

  const { recordWeight, updatedRecordWeight } =
    getRecordWeightForEligibleRecord({
      meet,
      eligibleRecord,
    });

  if (!recordWeight) {
    return null;
  }

  return (
    <div className="record-title">
      <FormattedEligibleRecord
        record={{ ...eligibleRecord, recordWeight, updatedRecordWeight }}
      />
    </div>
  );
};

const EligibleRecords = () => {
  const meet = useMeet();
  const lifter = useLifter();

  const eligibleRecords = getEligibleRecordsForLifter({ lifter, meet });

  return (
    <div className="eligible-records">
      <div>
        Possible eligible records. Only records meet director has loaded will
        show up here. Check official federation records database to confirm.
      </div>
      <br />
      {map(eligibleRecords, (eligibleRecord, index) => {
        return (
          <RecordsForType
            key={index}
            meet={meet}
            eligibleRecord={eligibleRecord}
          />
        );
      })}
    </div>
  );
};

export default EligibleRecords;
